import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context';
import { useLocation } from 'react-router-dom';
import { sections } from '../home/activeLinkArray';

const withSectionIds = (OriginalComponent) => {
  function NewComponent(props) {
    const [currentSection, setCurrentSection] = useState(0);
    const { pathname } = useLocation();

    // These sections are having light background so the text color should be black in following sections
    let lightSections = [
      'service',
      'work',
      'explore',
      'critical',
      'about',
      // 'talk',
      'career',
      'projects',
    ];

    // if the background color of the active section is lighter this function will return true
    const checkIsBlackColor = () => {
      return lightSections.includes(sections[currentSection]?.id);
    };

    // if we scrolled and the section is already visited (scrolled) it will return true
    const isVisitedSection = (sectionId) => {
      return (
        currentSection >=
        sections.indexOf(sections.find(({ id }) => sectionId === id))
      );
    };

    // if we give section name this function will check either the section is active or not
    const isActiveSection = (sectionId) => {
      return (
        sections.findIndex(({ id }) => sectionId === id) === currentSection
      );
    };

    const isPreviousSection = (sectionId) => {
      return (
        sections.findIndex(({ id }) => sectionId === id) === currentSection - 1
      );
    };

    const isNextSection = (sectionId) => {
      return (
        sections.findIndex(({ id }) => sectionId === id) === currentSection + 1
      );
    };

    // getting scroll position from context variable
    const scrollY = useContext(AppContext);

    // Updating the current section based on scroll position
    useEffect(() => {
      if (pathname === '/') {
        // getting all section tag in domSections
        const domSections = document.querySelectorAll('section');
        let currentSection = 0;

        domSections.forEach((section) => {
          const sectionTop = section.offsetTop;
          const sectionHeight = section.clientHeight;

          if (scrollY >= sectionTop - sectionHeight / 5) {
            currentSection = section.getAttribute('id');
          }
        });

        setCurrentSection(
          sections?.indexOf(sections.find(({ id }) => currentSection === id))
        );
      }
    }, [pathname,scrollY]);

    return (
      <OriginalComponent
        {...props}
        checkIsBlackColor={checkIsBlackColor}
        isVisitedSection={isVisitedSection}
        isActiveSection={isActiveSection}
        isPreviousSection={isPreviousSection}
        isNextSection={isNextSection}
        currentSection={currentSection}
        sections={sections}
      />
    );
  }
  return NewComponent;
};

export default withSectionIds;
