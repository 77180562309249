import React from 'react';
import { Link } from 'react-router-dom';
import SectionTtitle from '../common/sectionTtitle';

const TalkSection = () => {
  const mapdata = [
    {
      place: 'HYDERABAD',
      mapLink:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3484.179806716239!2d78.3843296746842!3d17.443900201175666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb915fb5062d11%3A0x83da91e46261c8ce!2sNo.57%2C%202nd%20floor%2C%202a%2C%20Hitech%20City%20Rd%2C%20near%20Keshav%20Reddy%20Sweets%2C%20Vinayaka%20Nagar%2C%20Arunodaya%20Colony%2C%20Sri%20Sai%20Nagar%2C%20Madhapur%2C%20Hyderabad%2C%20Telangana%20500081!5e1!3m2!1sen!2sin!4v1737358883660!5m2!1sen!2sin', // Embed link for Hyderabad
    },
    {
      place: 'GURUGRAM',
      mapLink:
        'https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d472.70532990423527!2d77.04902362900586!3d28.463972196813476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s509%2C%20Fifth%20Floor%2C%20JMD%20Pacific%20Square%20Sector%20%E2%80%93%2015%2C%20Gurugram%2C%20122001!5e1!3m2!1sen!2sin!4v1737358080211!5m2!1sen!2sin', // Embed link for Gurugram
    },
    {
      place: 'BANGALORE',
      mapLink:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.7141207107347!2d77.62488807507553!3d12.917454387392976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae159fcb64c527%3A0x87ab63b626c33505!2sVMA%20Labs%20Pvt%20Ltd!5e1!3m2!1sen!2sin!4v1737364971745!5m2!1sen!2sin', // Embed link for Bangalore
    },
    {
      place: 'UNITED STATES',
      mapLink:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2807.691038234373!2d-75.56316332402156!3d39.75553577155365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6fd11b1a1c621%3A0x302effa1e3ed54df!2s1401%20Pennsylvania%20Ave%20Ste%20105%2C%20Wilmington%2C%20DE%2019806%2C%20USA!5e1!3m2!1sen!2sin!4v1737358763289!5m2!1sen!2sin', // Embed link for USA
    },
    {
      place: 'SAUDI ARABIA',
      mapLink:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7621035.5523611335!2d41.49744441941777!3d24.178251229819644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15e7b33fe7952a41%3A0x5960504bc21ab69b!2sSaudi%20Arabia!5e1!3m2!1sen!2sin!4v1737365125173!5m2!1sen!2sin', // Embed link for Saudi Arabia
    },
  ];

  return (
    <section id='talk' className="bg-[#000] p-6">
      <SectionTtitle
        className="pt-2"
        title="Let's Talk"
        description="Let's Connect and Make Magic Happen!"
      />
      <div className="p-6">
        <div className="border-t-0 border-l-0 sm:!border-t-[5px] sm:border-l-[5px] border-[#1f1f1f] grid grid-cols-1 md:grid-cols-2">
          <div className="border-b-0 sm:border-b-[5px] border-[#1f1f1f] sm:p-10">
            <h1 className="text-[#ececec] text-[30px] sm:text-[35px] lg:text[50px] text-center">
              Let's Make It Happen
            </h1>
            <p className="text-[#ececec80] text-center text-sm sm:text-base lg:text-[26px] lg:leading-8 mt-5">
              Got a challenge or an idea? We're here, ready to listen! Visit our
              website at
              <Link
                to="https://vmalabs.com/"
                className="px-1 md:px-2 underline underline-offset-2 md:underline-offset-4 decoration-[1.4px] "
              >
                vmalabs.com
              </Link>
              to explore how we can turn your vision into reality.
            </p>
          </div>
          <div>
            <div className="grid gap-2 sm:gap-1 sm:grid-cols-2 lg:grid-cols-3 pl-2 pt-5 md:py-9">
              {mapdata.map(({ place, mapLink}) => (
                <div
                  key={place}
                  className="relative group border-[2px] border-[#5f5f5f30] bg-[#101010] p-3 overflow-hidden min-h-[159px] flex justify-center items-center"
                >
                  <>
                    <p className="text-[#cbcbcb] text-center text-xs xl:text-lg font-light absolute inset-0 flex justify-center items-center z-10 group-hover:opacity-0 transition-opacity duration-300">
                      {place}
                    </p>
                    <a
                      href={mapLink.replace('/embed?', '/maps?')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="text-sm tracking-widest font-extrabold text-white absolute z-20 bottom-3 left-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300 drop-shadow-lg shadow-[#000]">
                        {place}
                      </p>
                      <iframe
                        src={mapLink}
                        className="absolute z-10 top-0 right-[-100%] w-full h-full transition-all duration-500 group-hover:right-0"
                        loading="lazy"
                        allowFullScreen
                        title={place}
                      />
                    </a>
                  </>
                </div>
              ))}
              <div className="group min-h-[159px] flex justify-center items-center bg-[#2a2a2a] border-[#5f5f5f30] border-[2px] text-white font-medium">
                <Link
                  to="/contact"
                  className="w-full h-full flex justify-center items-center group-hover:bg-[#ffffff] group-hover:text-black transition-all duration-300"
                >
                  CONTACT US
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-left sm:text-center text-[#ececec80] text-sm sm:text-base lg:text-[22px] py-5">
        We can't wait to hear from you and walk on an exciting journey together!
      </div>
      {/* <div className="flex sm:hidden justify-end pt-3">
        <Link
          to="/contact"
          className="flex justify-center items-center bg-white border-white border-2 text-black  text-[16px] md:text-[20px] font-medium py-4 px-8 transition-all duration-500 !shadow-white/70 hover:translate-x-[4px] hover:translate-y-[-3px] hover:shadow-[-4px_3px]"
        >
          Contact Us
        </Link>
      </div> */}
    </section>
  );
};

export default TalkSection;
